import React from "react";
import Black1 from "../assets/image/teams/black1.jfif";
import Black2 from "../assets/image/teams/black2.jfif";
import Black3 from "../assets/image/teams/black3.jfif";
import Kollektiv from "../assets/image/teams/Kollektiv.jpeg"
const Collective = () => {
  return (
    <div
      class="flex items-center mt-6"
      // style="font-family: 'Muli', sans-serif;"
    >
      <div class="container ml-auto mr-auto flex flex-wrap items-start">
        <div class="w-full pl-5 lg:pl-2 mb-4 mt-4">
          <h1 class="text-3xl lg:text-4xl text-black font-extrabold">
            Komandamız
          </h1>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2 mt-8">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={Black1} alt="" class="h-64 ml-auto mr-auto" />
            </figure>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={Kollektiv} alt="" class="h-64 ml-auto mr-auto" />
            </figure>
          </div>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={Black2} alt="" class="h-64 ml-auto mr-auto" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collective;
