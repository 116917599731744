import React from "react";
import Carousel from "../../components/Carousel";
import Afl from "../../assets/image/courses/afl.jpg"
const Book = () => {
  return (
    <div>
      <div
        role="main"
        className="flex flex-col items-center justify-center mb-24"
      >
        <h1 className="text-4xl font-semibold leading-9 text-center text-black mt-64">
          Kitabımız
        </h1>
        <p className="text-lg leading-normal text-center text-black mt-4 lg:w-1/2 md:w-10/12 w-11/12">
          Kitablarımız və Proqramlarımız haqqında məlumat ala bilərsiniz.{" "}
        </p>
      </div>

      <Carousel />
      <section class="px-2 py-32 bg-white md:px-0 mt-32">
        <div class="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div class="flex flex-wrap items-center sm:-mx-3">
            <div class="w-full md:w-1/2 md:px-3">
              <div class="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                <h1 class="text-4xl font-semibold tracking-tight text-black sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                  AFL Geyim Üzrə Konstruksiya Və Modelləmə Üsulları
                </h1>
                <p class="mx-auto text-base text-black sm:max-w-md lg:text-xl md:max-w-3xl">
                  Bu kitabın əsas məqsədi tələbələrin öyrənmə prosesini
                  asanlaşdırmaqdır.Kitabdakı konstruksiya və modelləmə sistemi
                  Azərbaycana aid müəllif metodikasıdır.(Ruhiyyə Ə.)
                </p>
                <p>
                  AFL konstruksiya və modelləmə sistemində Azərbaycana xas insan
                  antropometriyası nəzərə alındığı üçün geyimlər bədənə qüsursuz
                  uyğunlaşır.Eyni zamanda bu kitab moda dizayneri,geyim
                  konstruktoru,modelyer və bu sahənin biznes sahiblərinə
                  istehsal prosesində istiqamət verəcək bir mənbədir. Günün
                  dəbinə uyğun dizayn edilən modellərin estetik görünməsi,
                  bədənə uyğunlaşması və hərəkət sabitliyini təmin etməsi ancaq
                  geyim qəliblərinin yaxşı işlənməsi ilə mümkündür.Parça
                  keyfiyyəti yüksək olsa belə deffektli qəliblə hazırlanmış
                  geyim məhsulunun satışı istənilən səviyyədən çox uzaqdır.Bu
                  çərçivədə hazır geyim istehsalında qəliblərin dəqiqliyi vacib
                  məsələlərdən birinə çevrilir. Konstruksiya,modelləmə və
                  serialama mərhələsini öyrədən bu kitab, təlabat və zövqə cavab
                  verən yüksək estetik və utilitar xüsusiyyətlərə malik geyim
                  məhsullarının yaradılması üçün nəzərdə tutulub.
                </p>

                <h5>
                  Müəlliflər: Ruhiyyə Əliyeva Nadir qızı, Pərvin Əyyubova Elman
                  qızı.
                </h5>
                <p>
                  <span className="font-bold">QEYD:</span> Bu kitabın təkrar və
                  hissə-hissə nəşri və çoxaldılması “Müəlliflik hüququ və
                  əlaqəli hüquqlar haqqında Azərbaycan Respublikasının Qanunu”na
                  ziddir.
                </p>
                <div class="relative flex flex-col sm:flex-row sm:space-x-4">
                  <a
                    href="#_"
                    class="flex items-center px-6 py-3 text-white bg-black rounded-md"
                  >
                    Daha çox
                  </a>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
                <img 
                src={Afl} width={700} height="700px"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Book;
