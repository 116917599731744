import { Routes, Route, useLocation } from "react-router-dom";

import Navigation from "./common/Header/Navigation";
import Home from "./pages/Home";
import About from "./pages/About";
import Book from "./pages/Book";
import Courses from "./pages/Courses";
import Cabinet from "./pages/Cabinet";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Footer from "./common/Footer/Footer";
import Login from "./pages/Auth/Login";
import CourseDetails from "./components/CourseDetails";

function App() {
  const location = useLocation();
  const hideLayout = ['/cabinet'].includes(location.pathname);

  return (
    <div className="montserrat-family">
      {!hideLayout && <Navigation />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/book" element={<Book />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<CourseDetails />} />
        <Route path="/cabinet" element={<Cabinet />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!hideLayout && <Footer />}
    </div>
  );
}

export default App;
