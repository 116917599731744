import React, { useState } from "react";
import styles from "./navigation.module.scss";
import { useLocation } from "react-router-dom";
import Logo from "../../components/Logo";
const Navigation = () => {
  const [close, setClose] = useState(false);

  const toggleBar = () => setClose(!close);

  const location = useLocation();
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <Logo />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={toggleBar}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a
              href="/"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Ana Səhifə
            </a>
            <a
              href="/about"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Haqqımızda
            </a>
            <a
              href="/book"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Kitabımız
            </a>
            <a
              href="/courses"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Kurslarımız
            </a>
            <a
              href="/cabinet"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Tələbə Kabineti
            </a>
            <a
              href="/contact"
              className="text-sm font-semibold leading-6 text-gray-900 mr-3"
            >
              Bizimlə Əlaqə
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <button className="px-6 py-1 rounded-lg bg-black text-white font-normal text-lg">
              Pdf yüklə
            </button>
          </div>
        </nav>
        <div
          className={`lg:hidden ${close ? null : "hidden"}`}
          role="dialog"
          aria-modal="true"
          onClick={toggleBar}
        >
          <div className="fixed inset-0 z-50"></div>
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <Logo />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={toggleBar}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 `ho`ver:bg-gray-50"
                  >
                    Ana Səhifə
                  </a>
                  <a
                    href="/about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 `ho`ver:bg-gray-50"
                  >
                    Haqqımızda
                  </a>
                  <a
                    href="/book"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 `ho`ver:bg-gray-50"
                  >
                    Kitabımız
                  </a>
                  <a
                    href="/courses"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Kurslarımız
                  </a>
                  <a
                    href="/cabinet"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Tələbə Kabineti
                  </a>
                  <a
                    href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Bizimlə Əlaqə
                  </a>
                </div>
                <div className="py-6">
                  <button className="px-12 py-1 rounded-lg bg-black text-white font-normal text-lg">
                    Pdf yüklə
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {location.pathname === "/" && (
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className={`relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]`}
              style={{
                clipPath:
                  "polygon(74.2% 44.1%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 25.8% 56.2%)",
              }}
            ></div>
          </div>

          {/* <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className={`relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]`}
              style={{
                clipPath:
                  "polygon(74.2% 44.1%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 25.8% 56.2%)",
              }}
            ></div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Navigation;
