import React from "react";

const Logo = () => {
  const logoStyle ={
    fontFamily: "'Jura', sans-serif"
  }
  return (
    <div className="logo text-center max-w-full md:max-w-2xl mx-auto" style={logoStyle}>
  <h1 className="text-xl md:text-2xl font-bold">AFL</h1>
  <hr className="h-px w-72 bg-gray-200 border-0 dark:bg-gray-700 my-2 mx-auto" />
  <h2 className="text-lg md:text-xl font-bold w-full md:w-96 mx-auto">
    AZERBAIJAN FASHION LAB
  </h2>
</div>

  );
};

export default Logo;
